import { get } from 'lodash';
import React, { memo, useContext } from 'react';
import Icons from '../Icons';
import PageContext from '../../../contexts/PageContext';

const BirthPlaceB = () => {
  const { data } = useContext(PageContext);
  const Icon = get(Icons, 'birthplace');

  if (data.profile.birthPlace) {
    return (
      <div className="text-xs flex items-center">
        <Icon
          size="10px"
          className="mr-2"
          style={{ color: data.metadata.colors.primary }}
        />
        <span className="font-medium break-all">{data.profile.birthPlace}</span>
      </div>
    );
  }

  return null;
};

export default memo(BirthPlaceB);
